import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container } from 'reactstrap';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import Jumbotron from '../../components/Jumbotron';
import { SECONDARY } from '../../utils/colors';
import HowItWorksItem from '../../components/HowItWorksItem';
import items from '../../components/HowItWorksItem/items';
import HowItWorksButton from '../../components/HowItWorksButton';

const Content = styled.section`
  transform: translateY(-200px);
  margin-bottom: -100px;
`;

const HowItWorksPage = ({ location }) => (
  <Layout theme="blue" location={location}>
    <SEO
      title="Como Calcular Frete com a Central do Frete"
      description="Saiba como funciona a Central do Frete e aprenda como calcular frete muito mais rápido e de forma descomplicada."
    />
    <Jumbotron
      background={SECONDARY}
      title={
        <>
          Fretes menos complicados,
          <br />
          empresas mais satisfeitas.
        </>
      }
      subtitle={
        <>
          Chegamos para criar uma experiência mais satisfatória tanto para quem
          <br />
          compra quanto para quem vende.
        </>
      }
    />
    <Content>
      <Container className="pb-5">
        {items.map((item, index) => (
          <HowItWorksItem
            {...item}
            isInverted={index % 2 === 1}
            key={index.toString()}
          />
        ))}

        <HowItWorksButton />
      </Container>
    </Content>
  </Layout>
);

HowItWorksPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default HowItWorksPage;
